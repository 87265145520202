:root {
  --darkRed: rgb(101, 9, 9);
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.bg-darkRed {
  background-color: var(--darkRed);
  border: none;
}
